import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  // const token = localStorage.getItem("token");
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const isLoggedIn = (token !== "" && token !== null && token !== undefined) ? true : false;
  const location = useLocation();
  const isAuthPath =
    location.pathname === "/register" || location.pathname === "/login"
      ? // location.pathname === '/registerusername'
        true
      : false;

  if (!isLoggedIn && location.pathname === "/whitelist") {
    return <Navigate to="/register" />;
  } else if (isLoggedIn && isAuthPath) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export default PrivateRoute;
