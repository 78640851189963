import { get } from "lodash";
import serverCall from "../serverCall";

const signIn = (userSignInRequest) => {
  return serverCall.post(`/login`, userSignInRequest).then((response) => {
    const data = get(response, "data", null);
    if (data) {
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      localStorage.setItem(
        "token",
        JSON.stringify(get(data, "access_token", null))
      );
      localStorage.setItem("email", get(data, "email", "Email"));
      if (data.username) {
        localStorage.setItem("username", get(data, "username", "Username"));
      } else {
        localStorage.setItem("username", null);
      }
      localStorage.setItem("userId", get(data, "_id", "userId"));
      localStorage.setItem("kycSessionId", get(data, "kycSessionId", ""));
      localStorage.setItem(
        "isKycDone",
        JSON.stringify(get(data, "isKycDone", false))
      );
    }
    return data;
  });
};

const logout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("userId");
  localStorage.clear();
};

const verificationCode = (email) => {
  return serverCall
    .get(`/users/send/verificationcode?email=${email}`)
    .then((response) => {
      return response;
    });
};

const signUp = (email, finalOtp) => {
  return serverCall
    .post(`/verifyUser?email=${email}&otp=${finalOtp}`)
    .then((response) => {
      const data = get(response, "data", null);
      if (data) {
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
        localStorage.setItem("email", data.email);
        localStorage.setItem("token", JSON.stringify(data.access_token));
        if (data.username) {
          localStorage.setItem("username", get(data, "username", "Username"));
        } else {
          localStorage.setItem("username", null);
        }
        localStorage.setItem("userId", get(data, "_id", "userId"));
        localStorage.setItem("kycSessionId", get(data, "kycSessionId", ""));
        localStorage.setItem(
          "isKycDone",
          JSON.stringify(get(data, "isKycDone", false))
        );
      }
      return data;
    });
};

const verifiyRegisterUser = (email) => {
  console.log("em,ai ", email);
  return serverCall.post(`/users/register?email=${email}`).then((response) => {
    return response;
  });
};

const getUserByToken = () => {
  return serverCall.get(`/users/getUserByToken`).then((response) => {
    const data = get(response, "data", null);
    if (data) {
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      localStorage.setItem("email", data.email);
      if (data.username) {
        localStorage.setItem("username", get(data, "username", "Username"));
      } else {
        localStorage.setItem("username", null);
      }
      localStorage.setItem("userId", get(data, "_id", "userId"));
      localStorage.setItem(
        "isKycDone",
        JSON.stringify(get(data, "isKycDone", false))
      );
    }
    return data;
  });
};

const AuthenticationService = {
  signIn,
  signUp,
  verificationCode,
  verifiyRegisterUser,
  logout,
  getUserByToken,
};

export default AuthenticationService;
