import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  token: null,
  error: false,
  isLoggedIn: !!JSON.parse(localStorage.getItem("isLoggedIn")),
  kycSessionId: "",
  walletAddressId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setKycSessionId: (state, action) => {
      state.kycSessionId = action.payload;
    },
    setWalletAddressId: (state, action) => {
      state.walletAddressId = action.payload;
    },
  },
});

const { reducer, actions } = authSlice;

export const { setLoggedIn, setKycSessionId, setWalletAddressId } = actions;
export default reducer;
