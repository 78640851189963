import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../stores/slice/auth';

const reducer = {
    auth: authReducer,
};

const store = configureStore({
    reducer,
    devTools: true,
});

export default store;