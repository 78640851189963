import React, { Suspense, lazy } from "react";
import { Spinner } from "src/components/Elements/Spinner/Spinner";
import { createBrowserRouter } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import PrivateRoute from "./PrivateRoute";

const Loader = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <Flex
          align={"center"}
          justify={"center"}
          w={"full"}
          h={"full"}
          pt={"50vh"}
        >
          <Spinner size="xl" />
        </Flex>
      }
    >
      <Component {...props} />
    </Suspense>
  );

const Register = Loader(lazy(() => import("src/features/auth/Register")));
const RegisterUser = Loader(
  lazy(() => import("src/features/auth/RegisterUsername"))
);
const Login = Loader(lazy(() => import("src/features/auth/Login")));
const Privacy = Loader(lazy(() => import("src/features/aution/Privacy")));
const Terms = Loader(lazy(() => import("src/features/aution/Terms")));
const Auction = Loader(lazy(() => import("src/features/aution/Auction")));
const MyAccount = Loader(
  lazy(() => import("src/features/myaccount/MyAccount"))
);
const NotFound = Loader(lazy(() => import("src/components/Layout/NotFound")));
const WhiteListed = Loader(
  lazy(() => import("src/features/whitelisted/WhiteListed"))
);
const Mint = Loader(lazy(() => import("src/features/mint/Mint")));
const LandingPage = Loader(
  lazy(() => import("src/features/LandingPage/LandingPage"))
);
const HatchEggs = Loader(lazy(() => import("src/features/Hatching/HatchEggs")));
const Earnings = Loader(lazy(() => import("src/features/Earnings/Earnings")));
const Leaderboard = Loader(
  lazy(() => import("src/features/Leaderboard/Leaderboard"))
);
const Inventory = Loader(
  lazy(() => import("src/features/Inventory/Inventory"))
);

const isLanding = false;

const routes = [
  {
    element: <WhiteListed />,
    path: "/claimbrush",
    errorElement: <NotFound />,
  },
  {
    element: <MyAccount />,
    path: "myaccount",
  },
  {
    element: (
      <PrivateRoute>
        <Register />
      </PrivateRoute>
    ),
    path: "register",
  },
  {
    element: (
      <PrivateRoute>
        <RegisterUser />
      </PrivateRoute>
    ),
    path: "registerusername",
  },
  {
    element: (
      <PrivateRoute>
        <Login />
      </PrivateRoute>
    ),
    path: "login",
  },
  {
    element: <Privacy />,
    path: "privacy",
  },
  {
    element: <Terms />,
    path: "terms",
  },
  //{
  //  element: <Mint />,
  //  path: "/mint",
  //},
  {
    element: <LandingPage />,
    path: "/",
  },
  {
    element: <HatchEggs />,
    path: "/hatching",
  },
  {
    element: <Earnings />,
    path: "/earnings",
  },
  {
    element: <Leaderboard />,
    path: "/leaderboard",
  },
  {
    element: (
      <PrivateRoute>
        <Inventory />
      </PrivateRoute>
    ),
    path: "/inventory",
  },
  {
    element: <NotFound />,
    path: "*",
    errorElement: <NotFound />,
  },
];

export const router = createBrowserRouter([...routes]);

// export default function Routes(props) {
//   const { isLanding } = props;
//   return useRoutes(routes(isLanding));
// }
