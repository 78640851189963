import axios from "axios";

import config from "./config/index";

const BASE_URL = config.API_URL;

const customAxios = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  if (localStorage.getItem("token")) {
    const token = JSON.parse(localStorage.getItem("token"));
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem("token");
    window.location = "/login";
  }

  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = async (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      // Do something, call refreshToken() request for example;
      // return a request
      localStorage.removeItem("token");
      localStorage.clear();
      //   window.location = "/login";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxios;
