const config = {
  API_URL: process.env.REACT_APP_BASE_API_URL,
  SYNAPS_API_KEY: process.env.REACT_APP_SYNAPS_API_KEY,
  SYNAPS_CLIENT_ID: process.env.REACT_APP_SYNAPS_CLIENT_ID,
  JWT_SECRET: '123456',
  whitepaperUrl: 'https://whitepaper.nilo.gg/',
  supportUrl: 'https://support.nilo.gg/',
  sibUrl:
    'https://fc35fbdd.sibforms.com/serve/MUIEAJS19h6MfZR8JZuowuoPPXuB2sh7zv_rv0uq608tC7kC9Ms52PO0q29bk4KCLed14tV7UEAQzuZt8hEkAvdCzlIDpv4pAShBv8TsMxzKjzi4AIbGxs1TWO-mP8SOqg5yzWfxr4RQSyfj49NNGu1MHGD8uaP6vEV2s4ntVmsdCLWa3RX-VrqTxZwgaDjHTkAmE8F2NCSsYTTt',
  PAGE_URLS: {
    myaccount: 'app/account',
    blog: '/blog',
  },
  TAGS: [
    'oralhealth',
    'events',
    'development',
    'tutorials',
    'partnerships',
  ],
  SOCIALS: {
    discord: 'https://discord.gg/nilo',
    twitter: 'https://twitter.com/collectnilo',
  }

}
export default config
